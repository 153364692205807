import React, { useState } from "react"

import CheckIcon from "../../images/icons/check.svg"
import { FORM_TYPES } from "../../../constants.js"

const ContactForm = ({ type, prefix, dropdown }) => {
  const selectOptions = {
    service: [
      "Ma commande",
      "Vos produits",
      "Demande de partenariat",
      "Un mot d'amour",
      "Autre",
    ],
    join: dropdown ? dropdown : ["Candidature spontannée"],
  }
  const [formSubmited, setFormSubmited] = useState(false)
  const [formPosted, setFormPosted] = useState(false)
  const [formError, setFormError] = useState(false)
  const [form, setForm] = useState({})

  const handleInput = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormPosted(true)
    const formEl = e.target
    fetch("/", {
      method: "POST",
      body: new FormData(formEl),
    })
      .then(response => {
        if (response.ok) {
          setFormSubmited(true)
          document
            .querySelector(".accordion__item--expanded")
            .scrollIntoView({ behavior: "smooth", block: "center" })
        } else if(response.status === 404 || !response.ok) {
          setFormError(true)
        }
      })
      .catch(error => {
        setFormError(true)
        console.warn(error)
      })
  }

  return (
    <>
      {formSubmited ? (
        <div className={"contact-form-confirm"}>
          <img src={CheckIcon} alt="" />
          <p className="confirmation">
            Votre message a bien été adressé à la Funky Team, nous vous
            répondrons dans les plus brefs délais !
          </p>
        </div>
      ) : (
        <form
          className="contact-form"
          name={prefix ? `${prefix}-contact-form` : `contact-form`}
          autoComplete="off"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={e => handleSubmit(e)}
        >
          <p className="mandatory">* champs obligatoires</p>
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input
            type="hidden"
            name={prefix ? `${prefix}-contact-form` : `contact-form`}
            value={prefix ? `${prefix}-contact-form` : `contact-form`}
          />
          <div hidden>
            <label>
              Don’t fill this out:
              <input name="bot-field" />
            </label>
          </div>
          <div className="field-wrapper">
            <input
              id={prefix ? `${prefix}-firstName` : `firstName`}
              type="text"
              name="firstName"
              required
              autoComplete="off"
              className={form.firstName ? "filled" : ""}
              onInput={e => handleInput(e)}
            />
            <label htmlFor={prefix ? `${prefix}-firstName` : `firstName`}>
              Nom*
            </label>
          </div>

          <div className="field-wrapper">
            <input
              id={prefix ? `${prefix}-lastName` : `lastName`}
              type="text"
              name="lastName"
              required
              autoComplete="off"
              className={form.lastName ? "filled" : ""}
              onInput={e => handleInput(e)}
            />
            <label htmlFor={prefix ? `${prefix}-lastName` : `lastName`}>
              Prénom*
            </label>
          </div>

          <div className="field-wrapper">
            <input
              id={prefix ? `${prefix}-email` : `email`}
              type="email"
              name="email"
              required
              autoComplete="off"
              className={form.email ? "filled" : ""}
              onInput={e => handleInput(e)}
            />
            <label htmlFor={prefix ? `${prefix}-email` : `email`}>Email*</label>
          </div>

          {type === FORM_TYPES.SERVICE || type === FORM_TYPES.JOIN ? (
            <div className="field-wrapper">
              <select
                id={prefix ? `${prefix}-subject` : `subject`}
                name="subject"
                defaultValue={"DEFAULT"}
                autoComplete="off"
                required={true}
                defaultValue={""}
                className={form.subject ? "filled" : ""}
                onInput={e => handleInput(e)}
              >
                <option disabled value="">
                  Objet
                </option>
                {selectOptions[type].map((elem, i) => (
                  <option key={i} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>
              <label htmlFor={prefix ? `${prefix}-subject` : `subject`}>
                Objet *
              </label>
            </div>
          ) : (
            ""
          )}

          {type === FORM_TYPES.SERVICE ? (
            <div className="field-wrapper">
              <input
                id={prefix ? `${prefix}-commandNumber` : `commandNumber`}
                type="text"
                name="commandNumber"
                autoComplete="off"
                className={form.commandNumber ? "filled" : ""}
                onInput={e => handleInput(e)}
              />
              <label
                htmlFor={prefix ? `${prefix}-commandNumber` : `commandNumber`}
              >
                Numéro de commande
              </label>
            </div>
          ) : (
            ""
          )}

          {type === FORM_TYPES.SELL ? (
            <div className="field-wrapper">
              <input
                id={prefix ? `${prefix}-enterprise` : `enterprise`}
                type="text"
                name="enterprise"
                autoComplete="off"
                className={form.enterprise ? "filled" : ""}
                onInput={e => handleInput(e)}
              />
              <label htmlFor={prefix ? `${prefix}-enterprise` : `enterprise`}>
                Societé
              </label>
            </div>
          ) : (
            ""
          )}

          {type === FORM_TYPES.SELL ? (
            <div className="field-wrapper">
              <input
                id={prefix ? `${prefix}-phone` : `phone`}
                type="tel"
                name="phone"
                autoComplete="off"
                className={form.phone ? "filled" : ""}
                onInput={e => handleInput(e)}
              />
              <label htmlFor={prefix ? `${prefix}-phone` : `phone`}>
                Téléphone
              </label>
            </div>
          ) : (
            ""
          )}

          <div className="field-wrapper field-wrapper--wide">
            <textarea
              id={prefix ? `${prefix}-message` : `message`}
              rows="5"
              name="message"
              autoComplete="off"
              required={true}
              className={form.message ? "filled" : ""}
              onInput={e => handleInput(e)}
            />
            <label htmlFor={prefix ? `${prefix}-message` : `message`}>
              Message *
            </label>
          </div>

          <div className="field-wrapper">
            <input
              id={prefix ? `${prefix}-file` : `file`}
              type="file"
              name="file"
              autoComplete="off"
              className={form.file ? "filled" : ""}
              onChange={e => handleInput(e)}
            />
            <label htmlFor={prefix ? `${prefix}-file` : `file`}>
              <span>Joindre un fichier</span>
            </label>
          </div>

          <div className="field-wrapper">
            <button
              className={"link link--white"}
              type="submit"
              disabled={formPosted}
            >
              {formPosted ? "..." : "Envoyer"}
            </button>
          </div>

          {formError && (
            <p>
              Nous avons rencontré une erreur lors de l'envoi du formulaire.
              <br />
              Vous pouvez néanmoins nous faire parvenir votre demande à cette
              adresse:
              <br />
              <a href={"mailto:contact@funkyveggie.fr"}>
                <strong>contact@funkyveggie.fr</strong>
              </a>
            </p>
          )}
        </form>
      )}
    </>
  )
}

export default ContactForm
