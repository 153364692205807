import { Accordion, AccordionItem } from "../components/accordion/accordion.js"

import ContactForm from "../components/form/form.js"
import { FORM_TYPES } from "../../constants.js"
import Layout from "../components/layout/layout"
import React from "react"
import SEO from "../components/seo.js"
import TitleHeader from "../components/title-header/title-header.js"
import { graphql } from "gatsby"
import useWindowSize from "../helpers/hooks/useWindowSize.js"

const Contact = ({ data, pageContext, location }) => {
  const sizes = useWindowSize()
  const getIndexFromHash = location => {
    let tabToOpen = parseInt(location.hash.charAt(1), 10)
    return tabToOpen >= 0 && tabToOpen <= 2 ? tabToOpen : 0
  }

  const pageContent = data.allWpPage.nodes[0].contact_data
  const seo = pageContext.page.seo

  let TabObjects = ['Candidature spontannée'];
  data.allWpJob.nodes.map((elem) => {
    TabObjects.push(elem.title);
  })

  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <div className={"container"}>
        <div className={"page-contact"}>
          <TitleHeader
            title={pageContent.title}
            paragraph={pageContent.content}
          />
          <h2>Pourquoi nous contacter ?</h2>
          <Accordion
            additionalClass="contact-accordion"
            itemClasses={["orange", "blue", "red"]}
            isTab={!sizes.isMobile}
            allowClose={sizes.isMobile}
            defaultIndex={getIndexFromHash(location) || 0}
          >
            <AccordionItem label={"Service client"} index={0} key={0}>
              <ContactForm
                type={FORM_TYPES.SERVICE}
                prefix={FORM_TYPES.SERVICE}
              />
            </AccordionItem>
            <AccordionItem label={"Rejoindre la Funky Team"} index={1} key={1}>
              <ContactForm type={FORM_TYPES.JOIN} prefix={FORM_TYPES.JOIN} dropdown={TabObjects} />
            </AccordionItem>
            <AccordionItem label={"Devenir revendeur"} index={2} key={2}>
              <ContactForm type={FORM_TYPES.SELL} prefix={FORM_TYPES.SELL} />
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactQuery {
    allWpPage(filter: { slug: { eq: "contact" } }) {
      nodes {
        contact_data {
          title
          content
        }
      }
    }
    allWpJob {
      nodes {
        title
      }
    }
  }
`
