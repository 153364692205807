import React from "react"

const TitleHeader = ({ title, paragraph }) => {
  return (
    <header className={"title-header"}>
      <h1 className={"title"} dangerouslySetInnerHTML={{ __html: title }} />
      <p
        className={"paragraph"}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    </header>
  )
}

export default TitleHeader
