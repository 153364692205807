import React, { useState, useEffect } from "react"

import ChevronDown from "../../images/icons/chevron-down.svg"

export const AccordionItem = ({
  label,
  title,
  isCollapsed,
  handleClick,
  children,
  className,
  isTab,
}) => {
  return (
    <div
      className={`accordion__item accordion__item${
        isCollapsed ? "--collapsed" : "--expanded"
      } ${className ? className : ""}`}
    >
      <img
        className={"accordion__chevron"}
        src={ChevronDown}
        alt={"X"}
        role="presentation"
      />
      {isTab ? (
        ""
      ) : (
        <button className={"accordion__item__button"} onClick={handleClick}>
          <span>{label ? label : title}</span>
        </button>
      )}
      <div className={"accordion__content"} aria-expanded={!isCollapsed}>
        {children}
      </div>
    </div>
  )
}

export const Accordion = ({
  additionalClass,
  itemClasses,
  defaultIndex,
  onItemClick,
  children,
  isTab,
  allowClose,
}) => {
  const [bindIndex, setBindIndex] = useState(defaultIndex)

  // useEffect(() => {
  //   changeItem(defaultIndex);
  // }, [defaultIndex])

  const changeItem = itemIndex => {
    if (typeof onItemClick === "function") onItemClick(itemIndex)
    if (itemIndex !== bindIndex) setBindIndex(itemIndex)
    if (allowClose && itemIndex === bindIndex) setBindIndex(-1)
  }
  return (
    <section className={additionalClass ? additionalClass : ""}>
      {isTab ? (
        <div className="accordion__tabs">
          {children.map(({ props }, i) => (
            <button
              key={i}
              className={`accordion__item__button ${
                itemClasses ? itemClasses[i] : ""
              } ${
                bindIndex !== props.index
                  ? "accordion__item__button--collapsed"
                  : "accordion__item__button--expanded"
              }`}
              onClick={() => changeItem(props.index)}
            >
              <span>{props.label ? props.label : props.title}</span>
            </button>
          ))}
        </div>
      ) : (
        ""
      )}
      {children.map(({ props }, i) => (
        <AccordionItem
          className={itemClasses ? itemClasses[i] : ""}
          key={i}
          isTab={isTab}
          isCollapsed={bindIndex !== props.index}
          label={props.label}
          handleClick={() => changeItem(props.index)}
          children={props.children}
        />
      ))}
    </section>
  )
}
